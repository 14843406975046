const OPERATIONS = [
  '=',
  '>',
  '<',
  '>=',
  '<=',
  '<>',
  'in',
  'is',
  'like'
]

export const OPTION_EMPTY = {
  label: '',
  value: ''
}

export const OPTION_ALL = {
  label: '* (all)',
  value: '*'
}

export const OPTION_FORMULA_BUILDER = {
  label: 'USE FORMULA BUILDER',
  value: 'formula'
}

export const OPERATIONS_OPTIONS = OPERATIONS.map(op => ({
  label: op,
  value: op
}))

export const OPTIONS_JOIN = [
  {
    label: 'Inner Join',
    value: 'Inner Join'
  },
  {
    label: 'Left Join',
    value: 'Left Join'
  },
  {
    label: 'Right join',
    value: 'Right join'
  },
  {
    label: 'Full Outer join',
    value: 'Full Outer join'
  }
]
