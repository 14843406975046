import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'

import {
  Grid,
  IconButton,
  TextField as MuiTextField,
  styled
} from '@mui/material'

const RootGrid = styled(Grid)(({ theme }) => ({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}))

const TextField = styled(MuiTextField)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  flex: 1
}))

const StyledButton = styled(IconButton)({
  padding: 5
})

export default function SearchBox (props) {
  return (
    <RootGrid
      data-testid={props['data-testid']}
      item xs={12}
    >
      <StyledButton size="large">
        <SearchIcon />
      </StyledButton>

      <TextField
        placeholder="Search"
        aria-label="Search"
        variant='standard'
        InputProps={{ disableUnderline: true }}
        onChange={props.onChange}
        value={props.value}
      />
    </RootGrid>
  )
}

SearchBox.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
