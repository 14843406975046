import React from 'react'
import Select from 'react-select'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MuiButton from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import FileDropZone from './hooks/useFileDropZone'
import FILE_TYPES from '../../store/objects/data/fileTypes.json'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import MessageDialog from '../../components/dialogs/MessageDialog'
import SelectSourceDialog from './dataLake/selectSourceDialog'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { fetchRoles } from '../../store/pages/dataLake'
import { tableRowBackGroundColor } from '../../components/table/table.utils'
import { changedFileTypeAction } from '../../store/pages/project/actions'
import { getAccess, deleteSource, deleteSourceFabric } from '../../store/pages/project/async'

const FILE_TYPES_EMPTY = []
const FILE_TYPES_SKIPMAP = ['I', 'T', 'RH', 'TY']
const FILE_TYPES_SKIPMAP_REQUIRED = ['I', 'T']

const StyledGrid = styled(Grid)(({
  backgroundColor: 'white'
}))

const StyledTableRow = styled(TableRow)(({
  height: '5'
}))

const Button = styled(MuiButton)(({
  '&.MuiButtonBase-root.MuiButton-root': {
    marginBottom: 8,
    width: '100%',
    borderWidth: 2,
    border: 'black dashed',
    boxShadow: 'none',
    padding: 20,
    backgroundColor: 'white',
    color: 'black'
  },
  '&.MuiButtonBase-root.MuiButton-root:hover': {
    color: 'none'
  }
}))

const getLabel = (type, isSkipMap) => {
  switch (type) {
    case 'I':
      return isSkipMap ? 'Val Policy' : 'Inforce'

    case 'T':
      return isSkipMap ? 'Val Terminated' : 'Termination'

    case 'RH':
      return 'Risk History'

    case 'TY':
      return 'Treaty'

    default:
      return type
  }
}

const getFileTypes = (studyType) => {
  switch (studyType) {
    case '':
    case 'redi':
      return FILE_TYPES_EMPTY

    case 'skipMap':
      return FILE_TYPES_SKIPMAP

    default:
      throw new RangeError(`Studytype invalid: ${studyType}`)
  }
}

export default function FileList () {
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)
  const user = useSelector(state => state.user)
  const project = useSelector(state => state.project)

  const isExistingProject =
    project.studyType === 'redi' &&
    project.files.some(file => file.src === 'dataFabric')

  const [open, setOpen] = React.useState(false)
  const [guid, setGuid] = React.useState('')
  const [source, setSource] = React.useState('')
  const [deletingSource, setDeletingSource] = React.useState(false)
  const [isRediFabric, setIsRediFabric] = React.useState(isExistingProject)
  const [openFabricNoDelete, setOpenFabricNoDelete] = React.useState(false)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'UPLOAD'
  )

  const deleteText = `Do you want to delete ${source}?`
  const isSkipMap = project.studyType === 'skipMap'
  const fileTypes = getFileTypes(project.studyType)

  const defaultFiles = React.useMemo(() => fileTypes.map(fileType => ({
    fileType,
    dataSet: null,
    columns: null,
    validation: null,
    dataFabric: null
  })), [fileTypes])

  const missingFileTypes = fileTypes.filter(fileType =>
    !project.files.find(file =>
      file.fileType === fileType
    )
  )

  const fileTypeOptions = isSkipMap
    ? FILE_TYPES.skipMap.filter(fileTypeOption =>
      missingFileTypes.find(fileType =>
        fileType === fileTypeOption.value
      )
    )
    : FILE_TYPES.default

  const filesData = React.useMemo(() => {
    if (!project.files || !project.files.length) {
      return defaultFiles
    }

    return project.studyType !== 'redi'
      ? defaultFiles.map(defaultFile => {
        const targetFile = project.files.find(file => (
          file.fileType === defaultFile.fileType)
        )

        return targetFile || defaultFile
      })
      : project.files.map(file => ({
        ...file,
        dataSet: file.fileName
      }))
  }, [defaultFiles, project.files, project.studyType])

  const handleAddDataFabricFilesClick = React.useCallback(() => {
    dispatch(fetchRoles)
    setOpen(true)
  }, [
    dispatch,
    setOpen
  ])

  const handleSourceDelete = React.useCallback(async () => {
    setDeletingSource(false)

    if (isSkipMap) {
      await dispatch(deleteSourceFabric(source))
    } else {
      await dispatch(deleteSource(guid))
    }
  }, [
    isSkipMap,
    guid,
    source,
    dispatch
  ])

  const handleDelete = React.useCallback(event => {
    const index = Number(event.currentTarget.getAttribute('data-index'))
    const file = project.files[index]

    if (
      project.studyType === 'skipMap' &&
      FILE_TYPES_SKIPMAP_REQUIRED.includes(file.type)
    ) {
      setOpenFabricNoDelete(true)
    } else {
      setGuid(file.guid)
      setSource(file.fileName)
      setDeletingSource(true)
    }
  }, [
    project.studyType,
    project.files
  ])

  const handleSelectFileType = React.useCallback((option, event) => {
    dispatch({
      ...changedFileTypeAction,
      payload: {
        key: 'fileType',
        value: option.value,
        index: Number(event.name)
      }
    })
  }, [dispatch])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleCloseFabricNoDelete = React.useCallback(() => {
    setOpenFabricNoDelete(false)
  }, [setOpenFabricNoDelete])

  const handleChange = React.useCallback(() => {
    setIsRediFabric(prev => !prev)
  }, [setIsRediFabric])

  return (
    <Grid>
      {access && (
        <ConfirmDialog
          data-testid='dialog-delete-confirm'
          open={deletingSource}
          title='Delete Source'
          text={deleteText}
          onCancel={() => setDeletingSource(false)}
          onConfirm={handleSourceDelete}
        />
      )}

      <MessageDialog
        data-testid='dialog-fabric-cannot-delete'
        open={openFabricNoDelete}
        title='Cannot Remove Dataset'
        text='This dataset is a required field. Please use the data source dialog to select a different dataset for this field.'
        onClose={handleCloseFabricNoDelete}
      />

      <StyledGrid >
        <Grid item xs={12}>
          <SelectSourceDialog
            open={open}
            fabricMapping={isRediFabric}
            files={filesData}
            onClose={handleClose}
          />

          <Grid container >
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  label='Use dataFabric files?'
                  control={
                    <Switch
                      checked={isRediFabric}
                      disabled={project.studyType !== 'redi' || !!project.id}
                      onChange={handleChange}
                    />
                  }
                />
              </FormGroup>

              {(isSkipMap || isRediFabric) && (
                <Button
                  data-testid='button-open'
                  variant='contained'
                  startIcon={<PlaylistAddIcon />}
                  onClick={handleAddDataFabricFilesClick}
                >
                  ADD DATA FABRIC FILES
                </Button>
              )}
            </Grid>
          </Grid>

          {access && !isSkipMap && !isRediFabric && <FileDropZone />}
        </Grid>

        {project.files.length > 0
          ? <Grid item xs={12}>
            <Table size='small'>
              <TableHead>
                <StyledTableRow >
                  <TableCell></TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Status</TableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {project.files.map((row, index) => (
                  <TableRow
                    key={index}
                    style={tableRowBackGroundColor(index)}
                  >
                    <TableCell>
                      {access && (
                        <IconButton
                          data-testid={`button-delete-${index}`}
                          data-index={index}
                          disabled={global.isSaving}
                          size='large'
                          onClick={handleDelete}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      )}
                    </TableCell>

                    <TableCell align='left'>
                      {row.fileName}
                    </TableCell>

                    <TableCell
                      align='left'
                      data-testid={`cell-fileType-${index}`}
                    >
                      {!row.src
                        ? (row.uploaded || global.isSaving
                            ? FILE_TYPES.default.find(option => option.value === row.fileType).label
                            : <Select
                                data-testid={`select-fileType-${index}`}
                                data-index={index}
                                index={index}
                                name={index}
                                options={fileTypeOptions}
                                onChange={handleSelectFileType}
                              />
                          )
                        : <div
                            data-testid={`row-get-label-${index}`}
                          >
                            {getLabel(row.fileType, isSkipMap)}
                          </div>
                      }
                    </TableCell>

                    <TableCell>
                      {row.isProcessing
                        ? row.uploaded
                          ? <div
                              data-testid='profiling'
                            >
                              Profiling

                              <LinearProgress color='primary' />
                            </div>
                          : <div
                              data-testid='uploading'
                            >
                              Uploading

                              <LinearProgress
                                variant='determinate'
                                value={row.uploadProgress}
                                color='primary'
                              />
                            </div>
                        : <div
                            data-testid={'profileStatus'}
                          >
                          {row.profileStatus || ''}

                          {row.profileStatus === 'Profiling'
                            ? <LinearProgress color='primary' />
                            : ''
                          }

                          {row.messageError && (
                            <Tooltip
                              data-testid='tooltip-error'
                              title={row.messageError}
                            >
                              <ErrorIcon color='primary' />
                            </Tooltip>
                          )}
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                )
                )}
              </TableBody>
            </Table>
          </Grid>
          : <div data-testid='label-no-files'></div>
        }
      </StyledGrid>
    </Grid>
  )
}
