import React from 'react'
import Select from 'react-select'
import AddIcon from '@mui/icons-material/Add'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import MappingList from './MappingList'
import ProfileCardAG from './ProfileCardAG'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import TableContainer from '@mui/material/TableContainer'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import SaveIcon from '@mui/icons-material/Save'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MappingsDrawer from './MappingsDrawer'
import SaveMappingsDialog from './SaveMappingsDialog'
import { styled } from '@mui/material'
import SearchBox from '../../components/form/SearchBox'
import useGetMapping from '../app/hooks/useGetMappings'
import { useDispatch, useSelector } from 'react-redux'
import { resetQueryAction } from '../../store/pages/common/dataQuery'
import { getAccess } from '../../store/pages/project/async'

import {
  SingleValue,
  SingleValueStyles
} from '../../components/sql/SelectSingleValue'

import {
  setIncludeValue,
  toggledSaveMappingsDialogAction,
  addedOptionalAction
} from '../../store/pages/mapping'

const EMPTY_MAPPING = {
  columns: [],
  optionalColumnCount: null
}

const OPTION_UNMAPPED = {
  value: 'Unmapped',
  label: 'Unmapped'
}

const RootGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'visible',
  minHeight: '400px'
}))

const ParentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.5)
}))

const ContainerGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'start',
  padding: theme.spacing(1)
}))

const NoFilesTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const TypographyCCFT = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const StyledForm = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const StyledSpinner = styled(CircularProgress)({
  marginTop: '200px',
  marginLeft: '45%'
})

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: '#DEDEDE'
}))

const StyledDiv = styled('div')(({
  backgroundColor: 'white'
}))

const StyledTableContainer = styled(TableContainer)(({
  display: 'block',
  overflow: 'auto',
  height: '800px'
}))

const SaveButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(-15)
}))

const ColumnMapping = () => {
  useGetMapping()

  const dispatch = useDispatch()
  const [search, setSearch] = React.useState('')
  const [showUnmappedColumns, setShowUnmappedColumns] = React.useState(true)
  const [sourceFocus, setSourceFocus] = React.useState('')
  const user = useSelector(store => store.user)
  const project = useSelector(store => store.project)
  const navigation = useSelector(store => store.navigation)
  const mapping = useSelector(store => store.mapping)

  const openSaveMappings = mapping.saveMappingsDialog.open || false
  const hasId = mapping.saveMappingsDialog.guid === undefined
  const isSkipMap = project.studyType === 'skipMap'
  const messageFabric = 'Column mapping is automatically done for data fabric skip mapping datasets'

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'MAPPING'
  )

  const searchFilter = search.toLowerCase()

  const fileMappings = mapping.mappings.find(mapping =>
    mapping.guid === navigation.file.guid
  ) || EMPTY_MAPPING

  const sourceColumnOptions = [
    ...navigation.file.columns.map(({ Name, Type: sourceDataType }, colIdx) => ({
      value: colIdx.toString(),
      label: Name,
      sourceDataType
    })),
    OPTION_UNMAPPED
  ]

  const mappingColumns = fileMappings.columns
    .filter(column => {
      if (showUnmappedColumns || !column.source) {
        return true
      }
      const sources = column.source.split('||')
      const optionsMatched = sourceColumnOptions.filter(option => sources.includes(option.label))
      return optionsMatched.length === 0
    })
    .filter(column => column.target
      .toLowerCase()
      .includes(searchFilter)
    )

  const fileMapping = {
    columns: mappingColumns,
    optionalColumnCount: fileMappings.optionalColumns
  }

  const totalMappedColumns = mappingColumns.reduce(
    (accum, curr) => !curr.value ? accum + 1 : accum,
    0
  )

  const totalColumns = mappingColumns.length

  if (
    (mapping.mappings.length === 0 || project.fileData === undefined) &&
    !(project.studyType === 'skipMap')
  ) {
    return (
      <RootGrid>
        <StyledSpinner/>
      </RootGrid>
    )
  }

  if (
    navigation.file === undefined ||
    Object.keys(navigation.file).length === 0 ||
    isSkipMap
  ) {
    return (
      <RootGrid>
        <NoFilesTypography variant="h5" component="h2">
          {isSkipMap
            ? messageFabric
            : ' No Files Processed Successfully'
          }
        </NoFilesTypography>
      </RootGrid>
    )
  }

  const relevantFile = project.fileData && project.fileData.find(x => x.guid === navigation.file.guid)
  const fileColumns = relevantFile ? relevantFile.columns : []
  const currentMappings = mapping.mappings.find(x => x.guid === navigation.file.guid)

  const fileColumnOptions = fileColumns
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .map((column, index) => ({
      value: index,
      label: column.Name,
      sourceDataType: column.Type
    }))

  const totalTypeValidationErrors = currentMappings?.totalErrosType ?? 0
  const remainingColumns = totalColumns - totalMappedColumns

  return (
    <StyledPaper>
      <Grid container rowGap={1} columnGap={1}>
        <Dialog
          open={openSaveMappings}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
          fullWidth={false}
        >
          <SaveMappingsDialog />
        </Dialog>

        <Grid item xs={4}>
          <TypographyCCFT >
            {`${remainingColumns} of ${totalColumns} Mapped / ${totalTypeValidationErrors} Validation Errors`}
          </TypographyCCFT>
        </Grid>

        <Grid item xs={5}>
          <StyledForm
            disabled={!access}
            label='Include in Common Format Table'
            control={<Switch
              size="small"
              color="primary"
              checked={currentMappings ? currentMappings.include : false}
              onChange={() => dispatch(setIncludeValue(navigation.file.guid, currentMappings.include))}
            />}
          />
        </Grid>

        {access && (
          <Grid item xs={2}>
            <SaveButton
              variant="contained"
              color="secondary"
              onClick={() => dispatch(toggledSaveMappingsDialogAction)}
            >
              <SaveIcon />

              {hasId ? 'Save Mappings' : 'Update Mappings'}
            </SaveButton>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <ParentGrid item xs={8} data-testid="column-table-parent">
          <StyledTableContainer>
            <StyledDiv>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <SearchBox onChange={e => setSearch(e.target.value)} />
                </Grid>

                <Grid item xs={6} align='right'>
                  <Typography color="textPrimary">
                    Show All Columns
                    <Switch
                      size="small"
                      color="primary"
                      checked={showUnmappedColumns}
                      onChange={() => setShowUnmappedColumns(!showUnmappedColumns)}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </StyledDiv>

            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid container alignItems="center">Actions</Grid>
                  </TableCell>

                  <TableCell>
                  </TableCell>

                  <TableCell>
                    <Grid container alignItems="center">
                      Target Column

                      <Divider orientation="vertical" />

                      {access && (
                        <IconButton
                          color="primary"
                          size="small"
                          aria-label="add"
                          onClick={() => dispatch({
                            ...addedOptionalAction,
                            payload: navigation.file.fileType
                          })}>
                          <AddIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell align="left">Source Column</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <MappingList
                  access={access}
                  studyType={project.studyType}
                  fileMapping={fileMapping}
                  fileData={project.fileData}
                  setSourceFocus={setSourceFocus}
                  onColumnChange={() => {}}
                />
              </TableBody>
            </Table>
          </StyledTableContainer>
        </ParentGrid>

        <ParentGrid item xs={4}>
          <ContainerGrid item xs={12} data-testid="source-focus-select-parent">
            <Select
              placeholder='Select a source column for profile info.'
              name='source-focus-select'
              value={sourceFocus}
              aria-label="source-focus-label"
              options={fileColumnOptions}
              styles={SingleValueStyles}
              components={{ SingleValue }}
              onChange={value => {
                dispatch(resetQueryAction)
                setSourceFocus(value)
              }}
            />
          </ContainerGrid>

          <ContainerGrid item xs={12}>
            <Divider variant="middle" />
          </ContainerGrid>

          <ContainerGrid item xs={12}>
            <ProfileCardAG
              guid={navigation.file.guid}
              data={project.profileData}
              source={sourceFocus}
            />
          </ContainerGrid>
        </ParentGrid>
      </Grid>

      <Grid item xs={4}>
        <MappingsDrawer />
      </Grid>
    </StyledPaper>
  )
}

export default ColumnMapping
