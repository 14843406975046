import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SaveIcon from '@mui/icons-material/Save'
import Navigation from '../components/navigation'
import StepsContainer from './Steps'
import Info from './Info'
import {
  clearedWorkflowAction,
  executeWorkflow,
  saveWorkflow,
  toggledAddNewStepAction
} from '../../../store/pages/common/workflow'
import {
  validateFields,
  isOneStepChecked,
  validateStepFields
} from './validation'

export default function Workflow () {
  const dispatch = useDispatch()
  const {
    desc,
    guid,
    isExecuting,
    isSaving,
    name,
    steps
  } = useSelector(({
    workflow: {
      desc,
      guid,
      isExecuting,
      isSaving,
      name,
      steps
    }
  }) => ({
    desc,
    guid,
    isExecuting,
    isSaving,
    name,
    steps
  }))
  const hasId = guid !== undefined && guid !== null && guid !== ''

  const isExecuteDisabled = validateFields(desc, name, steps) ||
  isSaving ||
  isExecuting ||
  !isOneStepChecked(steps) ||
  validateStepFields(steps)

  return (
    <Grid>
      <Grid item xs={12}>
        <Navigation/>
      </Grid>
      <Grid container spacing={3} style={{ paddingLeft: '16px' }}>
        <Grid item xs={12}>
          <Info/>
        </Grid>
        <Grid item xs={12}>
          {
            isSaving
              ? <CircularProgress />
              : <Button
                data-testid='button-save/update'
                disabled={validateFields(desc, name, steps) || isExecuting}
                variant="contained"
                color="secondary"
                onClick={() => dispatch(saveWorkflow)}
              >
                <SaveIcon />
                {hasId ? 'UPDATE' : 'SAVE'}
              </Button>
          }
          {
            <Button
              data-testid='button-newWorkflow'
              disabled={validateFields(desc, name, steps) || isExecuting}
              variant="outlined"
              style={{ marginLeft: '5px' }}
              color="primary"
              onClick={() => dispatch(clearedWorkflowAction)}
            >
              <AutorenewIcon /> New Workflow </Button>
          }
        </Grid>
        <Grid item xs={12}>
          {steps.map((value, index) => {
            return (
              <StepsContainer
                view = {value}
                uniqueKey = {index}
                key = {index}
                order = {index}
                isExecuting = {isExecuting}
                isSaving = {isSaving}
              />
            )
          })}
        </Grid>
        <Grid item xs={12}>
          <Button
            data-testid='button-addStep'
            disabled={isSaving || isExecuting}
            variant="outlined"
            color="primary"
            onClick={() => dispatch(toggledAddNewStepAction)}
          >
            <AddIcon /> Add Step </Button>
          {isExecuting
            ? <CircularProgress size={25}/>
            : <Button
                data-testid='button-execute'
                variant="contained"
                disabled={isExecuteDisabled}
                color="secondary"
                style={{ marginLeft: '5px' }}
                onClick={() => dispatch(executeWorkflow)}
              >
              <PlayArrowIcon />
                Execute
              </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
