import { API } from 'aws-amplify'
import apiHelper, { client } from './apiHelper'
import countries from './data/countries.json'
import dataFrequencyType from './data/dataFrequency.json'
import policyRecordStructure from './data/policyRecordStructure.json'
import products from './data/products.json'
import rgaColumns from './data/rga_columns.json'
import studyTypes from './data/studyTypes.json'
import yesNo from './data/yesNo.json'
import segmentReceiver from './data/SegmentReceiver.json'

const firstIndexReferenceData = {
  products,
  countries,
  dataFrequencyType,
  policyRecordStructure,
  studyTypes,
  yesNo
}

export const referenceData = {
  ...firstIndexReferenceData,
  rgaColumns
}
const getReferenceData = () => {
  const {
    REACT_APP_RGAENV: rgaEnv
  } = process.env
  const isLowerEnv = ['poc', 'sdlc'].includes(rgaEnv);
  const filteredCountries = isLowerEnv
    ? firstIndexReferenceData.countries.filter((country) => country.region !== 'ap-northeast-1')
    : countries;

  return Promise.all([
    Promise.resolve({ ...firstIndexReferenceData, countries: filteredCountries }),
    Promise.resolve(rgaColumns)
  ])
}

const regionConfig = {
  apiRegion: process.env.REACT_APP_REGION
}

const setAPIRegion = region => {
  regionConfig.apiRegion = region
}

const searchReferenceTable = async (searchText, order, orderBy, page, rowsPerPage, ownerId, region) => {
  const header = await apiHelper.getHeader()
  const pageQuery = page >= 0 && rowsPerPage > 0 ? `pageNo=${page + 1}&pageSize=${rowsPerPage}` : ''
  const sortQuery = order && orderBy ? `sortColumn=${orderBy}&sortOrder=${order}` : ''
  const searchQuery = searchText.length > 2 ? `&searchText=${searchText}` : ''
  const ownerFilter = ownerId ? `&ownerId=${ownerId}` : ''
  const regionFilter = region ? `&region=${region.replace('-1', '')}` : ''
  return API.get(apiHelper.apiName, `/reference/search?${pageQuery}&${sortQuery}${searchQuery}${ownerFilter}${regionFilter}`, header).catch(apiHelper.handleError)
}

const getReferenceTable = async (id) => {
  const header = await apiHelper.getHeader()
  return API.get(apiHelper.apiName, `/reference/crud/reference?id=${id}`, header).catch(apiHelper.handleError)
}

const createReferenceTable = async (body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  return API.post(apiHelper.apiName, '/reference/crud/reference?id=-1', bodyAndHeader).catch(apiHelper.handleError)
}

const updateReferenceTable = async (id, body) => {
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  return API.put(apiHelper.apiName, `/reference/crud/reference?id=${id}`, bodyAndHeader).catch(apiHelper.handleError)
}

const deleteReferenceTable = async (id) => {
  const header = await apiHelper.getHeader()
  return API.del(apiHelper.apiName, `/reference/crud/reference?id=${id}`, header).catch(apiHelper.handleError)
}

const getSegments = async () => {
  const header = await apiHelper.getHeader()
  const segments = process.env.REACT_APP_RGAENV === 'poc'
    ? segmentReceiver.segment
    : API.get(apiHelper.apiName, '/edp/segment', header).catch(apiHelper.handleError)
  return segments
}
const getReceivers = async (id) => {
  const header = await apiHelper.getHeader()
  const receiver = process.env.REACT_APP_RGAENV === 'poc'
    ? segmentReceiver.receiver
    : API.get(apiHelper.apiName, '/edp/segment', header).catch(apiHelper.handleError)
  return receiver
}

const getBasis = async () => {
  const res = process.env.REACT_APP_RGAENV === 'poc' ? [] : await client.get('/source/basis')

  const items = res.rows || []

  /*
    NOTES:
    - Incoming 'case#' field is a numeric string-type, but has a floating-point value
    - Example: a case# field would come in as '384.000000000000000000' instead of just '384'
    - The mapping operation below fixes this by coercing to a Number-type, then back to string
    - We coerce back to a string because this field has no arithmetic significance
   */

  return items.map(item => ({
    caseNumber: `${parseInt(item['case#'])}`, // gets rid of floating-point zeroes
    name: item.name,
    description: item.description
  }))
}

export default {
  getReferenceData,
  searchReferenceTable,
  getReferenceTable,
  createReferenceTable,
  setAPIRegion,
  updateReferenceTable,
  deleteReferenceTable,
  getReceivers,
  getSegments,
  getBasis
}
