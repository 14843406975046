import { API } from 'aws-amplify'
import apiHelper from './apiHelper'
import delay from '../pages/common/delay'

const MAX_TRIES = 2

let __region = process.env.REACT_APP_REGION

const setAPIRegion = region => {
  __region = region
}

const getExpVsRiskAmountCheckData = async (projectId, jobId) => {
  const body = {
    check: 'riskHistoryAmount',
    projectId,
    jobId
  }
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)

  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const message = error.response.data.error
      if (message.includes('does not exist')) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getExpVsRiskCountCheckData = async (projectId, jobId) => {
  const body = {
    check: 'riskHistoryCount',
    projectId,
    jobId
  }
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }

      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getPostCalLapseData = async (projectId, jobId) => {
  const body = {
    check: 'table',
    tableName: `redi_${projectId}_postcal_${jobId}.lapse_check`,
    projectId
  }

  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getPostCalPolicyTrackerData = async (projectId, jobId) => {
  const body = {
    check: 'table',
    tableName: `redi_${projectId}_postcal_${jobId}.policy_tracker_check`,
    projectId
  }

  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getTerminatingClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'terminatingClaims',
    projectId,
    jobId
  }
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getNonTerminatingClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'nonTerminatingClaims',
    projectId,
    jobId
  }
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getTerminatingOvsQClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'term_OvsQ',
    projectId,
    jobId
  }

  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

const getNonTerminatingOvsQClaimsData = async (projectId, jobId) => {
  const body = {
    check: 'nonTerm_OvsQ',
    projectId,
    jobId
  }
  const bodyAndHeader = await apiHelper.getBodyAndHeader(body)
  let count = 0

  while (true) {
    try {
      const result = await API.post(
        apiHelper.getRegionalAPIName(__region),
        '/source/redshift',
        bodyAndHeader
      )
      return result
    } catch (error) {
      const errorText = error?.response?.data?.error
      if (errorText.indexOf('does not exist') > 0) {
        throw error
      }
      await delay(40000)
      if (++count === MAX_TRIES) {
        throw new Error('Max tries have been met')
      }
    }
  }
}

export default {
  getPostCalLapseData,
  getExpVsRiskCountCheckData,
  getExpVsRiskAmountCheckData,
  getPostCalPolicyTrackerData,
  getTerminatingClaimsData,
  getNonTerminatingClaimsData,
  getTerminatingOvsQClaimsData,
  getNonTerminatingOvsQClaimsData,
  setAPIRegion
}
