import { filesWereLoadedAction } from './dataPrep'
import { updatedSourceFilesAction, setProjectFileDirtyFlagAction, loadedProjectDataAction } from './project/actions'
import { createdCFAction } from './valueMapping'

export const initialState = {
  file: { columns: [] },
  files: [],
  formatFile: undefined,
  formatFiles: [],
  currentTab: undefined
}

export const resetNavAction = {
  type: 'navigation/RESET_NAVIGATION'
}

export const setFileAction = {
  type: 'navigation/SET_FILE'
}

export const setFilesAction = {
  type: 'navigation/SET_FILES'
}

export const setCommonFileAction = {
  type: 'navigation/SET_COMMON_FILE'
}

export const setFile = guid => async (dispatch, getState) => {
  const {
    navigation: { files },
    project: { fileData }
  } = getState()
  const selectedFile = files.find(x => x.guid === guid)
  const foundFileData = fileData.find(y => y.guid === selectedFile.guid)
  return dispatch({
    ...setFileAction,
    payload: {
      file: { ...selectedFile, columns: foundFileData !== undefined ? foundFileData.columns : [] },
      files
    }
  })
}

export const setCommonFile = currentTab => async (dispatch, getState) => {
  const { project: { formatFiles } } = getState()
  if (currentTab === 'precalCheck') {
    return dispatch({
      ...setCommonFileAction,
      payload: {
        formatFile: formatFiles[0],
        formatFiles,
        currentTab
      }
    })
  } else {
    const selectedFile = formatFiles ? formatFiles.find(x => x.type === currentTab) : null
    if (selectedFile) {
      return dispatch({
        ...setCommonFileAction,
        payload: {
          formatFile: selectedFile,
          formatFiles,
          file: { ...selectedFile },
          currentTab
        }
      })
    }
    return Promise.resolve()
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case resetNavAction.type:
      return {
        ...initialState
      }
    case setFileAction.type:
    case setFilesAction.type:
    case setCommonFileAction.type:
      return {
        ...state,
        ...payload
      }
    case updatedSourceFilesAction.type:
    case createdCFAction.type:
    case loadedProjectDataAction.type:
    case filesWereLoadedAction.type:
      return {
        ...state,
        ...payload.navigation
      }
    case setProjectFileDirtyFlagAction.type:
      return payload
        ? {
            ...state,
            files: payload
          }
        : state
    default:
      return state
  }
}
