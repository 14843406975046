import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import LatestRunSection from './LatestRunSection'
import DataChecksSection from './DataChecksSection'
import usePostCal from '../app/hooks/usePostCal'
import calculationApi from '../../store/objects/calculationApi'
import { styled } from '@mui/material'
import { useSelector } from 'react-redux'

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  minHeight: 500,
  backgroundColor: '#DEDEDE'
}))

const Layout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4),
  gridTemplateColumns: '1fr'
}))

export default function PostCalculation () {
  usePostCal()

  const calculation = useSelector(store => store.calculation)
  const postCalc = useSelector(store => store.postCalc)

  const jobs = React.useMemo(() =>
    calculation.jobHistory
      ? calculation.jobHistory.map(calculationApi.formatJob)
      : []
  , [calculation.jobHistory])

  const completedChecks = !postCalc.postCalculationCheckRunning

  const latestJob = jobs[0] || null

  return (
    <Root data-testid='root'>
      {latestJob
        ? (
          <Layout>
            <LatestRunSection
              data-testid='section-latest-run'
              completedChecks={completedChecks}
              job={latestJob}
            />

            <DataChecksSection
              data-testid='section-data-checks'
              postCalc={postCalc}
              jobs={jobs}
            />
          </Layout>
          )
        : null
      }
    </Root>
  )
}
