import sourcesApi from '../../objects/sourcesAPI'
import apiHelper from '../../objects/apiHelper'
import moment from 'moment'
import delay from './delay'
import { openWorkflowAction } from '../dataPrep'
import { updateOptionSet, updateOptionWhere } from './formulaDialog'
import { loadData } from '../project/async'

const initialState = {
  name: '',
  fileType: '',
  desc: '',
  createdDate: '',
  updatedDate: '',
  guid: '',
  steps: [],
  addNewStep: {
    name: '',
    stepType: ''
  },
  openAddNewDialog: false,
  isSaving: false,
  isExecuting: false
}

export const changedJoinItemAction = {
  type: 'workflow/CHANGED_JOIN_ITEM'
}

export const removedJoinItemAction = {
  type: 'workflow/REMOVED_JOIN_ITEM'
}

export const addedJoinItemAction = {
  type: 'workflow/ADDED_JOIN_ITEM'
}

export const addedWhereItemAction = {
  type: 'workflow/ADDED_WHERE_ITEM'
}

export const updatedWhereItemAction = {
  type: 'workflow/UPDATED_WHERE_ITEM'
}

export const removedWhereItemAction = {
  type: 'workflow/REMOVED_WHERE_ITEM'
}

export const expandAction = {
  type: 'workflow/EXPAND'
}

export const toggledWorkflowFormulaAction = {
  type: 'workflow/TOGGLED_WORKFLOW_FORMULA'
}

export const setWorkFlowNewStepFieldAction = {
  type: 'workflow/SET_WORKFLOW_ADD_NEW_STEP_FIELD'
}

export const setWorkFlowFieldAction = {
  type: 'workflow/SET_WORKFLOW_FIELD'
}

export const savedNewStepAction = {
  type: 'workflow/SAVED_NEW_STEP'
}

export const changedNewColumnAction = {
  type: 'workflow/CHANGED_NEW_COLUMN'
}

export const selectedFieldsAction = {
  type: 'workflow/SELECTED_FIELDS'
}

export const selectAggregatorsAction = {
  type: 'workflow/SELECT_AGGREGATORS'
}

export const removedSelectedAggregatorAction = {
  type: 'workflow/REMOVED_AGGREGATOR'
}

export const selectAggregationAction = {
  type: 'workflow/SELECT_AGGREGATION'
}

export const addedNewUpdateSetConditionAction = {
  type: 'workflow/ADDED_NEW_UPDATE_SET_CONDITION'
}

export const addedNewWhereConditionAction = {
  type: 'workflow/ADDED_NEW_WHERE_CONDITION'
}

export const addedOrFilterAction = {
  type: 'workflow/ADDED_OR_FILTER'
}

export const updatedWhereListAction = {
  type: 'workflow/UPDATED_WHERE_LIST'
}

export const removedWhereConditionAction = {
  type: 'workflow/REMOVED_WHERE_CONDITION'
}

export const removedSetConditionAction = {
  type: 'workflow/REMOVED_SET_CONDITION'
}

export const updatedSetListAction = {
  type: 'workflow/UPDATED_SET_LIST'
}
export const removedOrConditionsAction = {
  type: 'workflow/REMOVED_OR_CONDITIONS'
}

export const updatingMergeReferenceAction = {
  type: 'workflow/UPDATING_MERGE_REFERENCE'
}

export const updatedMergeReferenceAction = {
  type: 'workflow/UPDATED_MERGE_REFERENCE'
}

export const toggledAddNewStepAction = {
  type: 'workflow/TOGGLED_ADD_NEW_STEP'
}

export const deletedStepAction = {
  type: 'workflow/DELETED_STEP'
}

export const checkedStepAction = {
  type: 'workflow/CHECKED_STEP'
}

export const movedUpAction = {
  type: 'workflow/MOVED_UP'
}

export const movedDownAction = {
  type: 'workflow/MOVED_DOWN'
}

export const savingWorkflowAction = {
  type: 'workflow/SAVING_WORKFLOW'
}

export const savedWorkflowAction = {
  type: 'workflow/SAVED_WORKFLOW'
}

export const clearedWorkflowAction = {
  type: 'workflow/CLEARED_WORKFLOW'
}

export const executingWorkflowAction = {
  type: 'workflow/EXECUTING'
}

export const executingSavedWorkflowAction = {
  type: 'workflow/EXECUTING_SAVED'
}

export const executedWorfklowAction = {
  type: 'workflow/EXECUTED'
}

const buildStepState = (stepType) => {
  switch (stepType) {
    case 'convert': {
      return {
        fieldsToConvert: { field: null, dataType: null, format: null },
        isSaving: false
      }
    }
    case 'newColumn': {
      return {
        name: '',
        type: '',
        formula: '',
        flag: false,
        isLoading: false,
        message: '',
        refreshData: false,
        isFormulaDialogOpen: false
      }
    }
    case 'aggregate': {
      return {
        selectedAggregators: [],
        fieldsAgg: []
      }
    }
    case 'removeRows':
    case 'modify': {
      return {
        updateSetConditions: [{ columnName: '', operation: '=', whereValue: '' }],
        updateWhereConditions: [{ columnName: '', operation: '', whereValue: '' }],
        deleteWhereConditions: [{ columnName: '', operation: '', whereValue: '' }],
        isFormulaDialogOpen: false,
        orSwitchChecked: [...Array(10).keys()].map(v => false)
      }
    }
    case 'merge': {
      return {
        isLoading: false,
        fileColumns: [],
        referenceTable: {},
        referenceColumns: [],
        joinType: '',
        joinList: [{
          sourceColumn: {},
          sourceOperation: {},
          referenceColumn: {}
        }],
        whereList: [{
          sourceColumn: {},
          sourceOperation: {},
          referenceColumn: {}
        }]
      }
    }
  }
}

const getAggValue = (agg, field) => {
  let value
  if (agg.__isNew__ === true) {
    value = `${agg.value} as '${field}'`
  } else {
    value = `${agg.value}(${field}) as '${field}'`
  }
  return value
}

const getUpdatedSteps = (steps, order, key, value) => {
  const updatedStep = {
    ...steps[order],
    state: {
      ...steps[order].state,
      [key]: value
    }
  }
  return [
    ...steps.slice(0, order),
    updatedStep,
    ...steps.slice(order + 1)
  ]
}

const getUpdatedConditions = (steps, order, conditions, index, key, value) => {
  const updatedCondition = {
    ...steps[order].state[conditions][index],
    [key]: value
  }
  return [
    ...steps[order].state[conditions].slice(0, index),
    updatedCondition,
    ...steps[order].state[conditions].slice(index + 1)
  ]
}

export const changedNewColumn = (field, value, order) => async (dispatch, getState) => {
  const { workflow: { steps } } = getState()
  const newColumn = {
    ...steps[order].state
  }
  const getNewCol = () => {
    switch (field) {
      case 'formula':
        newColumn[field] = { label: value, value, type: 'formula' }
        return newColumn
      case 'name':
        newColumn[field] = value
        if (value) {
          value.value = value.value.replace(/\s/g, '_')
        }
        return newColumn
      default:
        newColumn[field] = value
        return newColumn
    }
  }
  const newState = getNewCol()
  const updatedStep = {
    ...steps[order],
    state: {
      ...newState,
      isFormulaDialogOpen: field === 'formulaDialog'
    }
  }

  const updatedSteps = [
    ...steps.slice(0, order),
    updatedStep,
    ...steps.slice(order + 1)
  ]
  return dispatch({
    ...changedNewColumnAction,
    payload: {
      formulaDialog: {
        open: field === 'formulaDialog'
      },
      workflow: {
        steps: updatedSteps
      }
    }
  })
}

export const updatedWhereList = (key, index, value = '', order, isDelete) => async (dispatch, getState) => {
  const { workflow: { steps } } = getState()
  if (value !== null && value.value === 'USE FORMULA BUILDER') {
    dispatch({ ...toggledWorkflowFormulaAction, payload: { order } })
    return dispatch(updateOptionWhere(key, index, value, false))
  } else {
    const whereConditions = isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'
    const updatedConditions = getUpdatedConditions(steps, order, whereConditions, index, key, value)
    const updatedSteps = getUpdatedSteps(steps, order, whereConditions, updatedConditions)
    return dispatch({
      ...updatedWhereListAction,
      payload: { steps: updatedSteps }
    })
  }
}

export const updateOptionSetList = (key, index, value = '', order) => async (dispatch, getState) => {
  const { workflow: { steps } } = getState()
  if (value !== null && value.value === 'USE FORMULA BUILDER') {
    dispatch({ ...toggledWorkflowFormulaAction, payload: { order } })
    return dispatch(updateOptionSet(key, index, value))
  } else {
    const updatedConditions = getUpdatedConditions(steps, order, 'updateSetConditions', index, key, value)
    const updatedSteps = getUpdatedSteps(steps, order, 'updateSetConditions', updatedConditions)
    return dispatch({
      ...updatedSetListAction,
      payload: { steps: updatedSteps }
    })
  }
}

export const removeOrConditions = (key, index, order, isRemoveRows) => async (dispatch, getState) => {
  const { workflow: { steps } } = getState()
  const conditions = isRemoveRows ? 'deleteWhereConditions' : 'updateWhereConditions'
  const updatedCondition = {
    ...steps[order].state[conditions][index]
  }
  delete updatedCondition[key]
  const updatedConditions = [
    ...steps[order].state[conditions].slice(0, index),
    updatedCondition,
    ...steps[order].state[conditions].slice(index + 1)
  ]
  const updatedSteps = getUpdatedSteps(steps, order, conditions, updatedConditions)
  return dispatch({
    ...removedOrConditionsAction,
    payload: { steps: updatedSteps }
  })
}

export const updateReferenceColumns = (order) => async (dispatch, getState) => {
  const {
    project: { country },
    workflow: { steps }
  } = getState()

  if (!Object.keys(steps[order].state.referenceTable).length) {
    return dispatch({
      ...updatedMergeReferenceAction,
      payload: {
        dataPrep: {
          referenceColumns: []
        }
      }
    })
  }

  const mergeData = {
    ...steps[order].state,
    isLoading: true
  }

  const updatedStep = {
    ...steps[order],
    state: mergeData
  }

  const updatedSteps = [
    ...steps.slice(0, order),
    updatedStep,
    ...steps.slice(order + 1)
  ]

  dispatch({
    ...updatingMergeReferenceAction,
    payload: {
      workflow: { steps: updatedSteps }
    }
  })

  const { region } = apiHelper.getCountry(country)
  sourcesApi.setAPIRegion(region)

  const sourceData = await sourcesApi.getSourceDataOnly(mergeData.referenceTable.value)
  mergeData.isLoading = false

  mergeData.referenceTable.tableName = sourceData[0].guid

  return dispatch({
    ...updatedMergeReferenceAction,
    payload: {
      dataPrep: { referenceColumns: sourceData[0].columns },
      workflow: { steps: updatedSteps }
    }
  })
}

export const updateMergeReference = (key, value, order) => async (dispatch, getState) => {
  const {
    workflow: { steps }
  } = getState()

  const mergeData = {
    ...steps[order].state,
    [key]: value
  }

  const updatedStep = {
    ...steps[order],
    state: mergeData
  }

  const updatedSteps = [
    ...steps.slice(0, order),
    updatedStep,
    ...steps.slice(order + 1)
  ]

  dispatch({
    ...updatedMergeReferenceAction,
    payload: {
      workflow: { steps: updatedSteps }
    }
  })
}

export const saveNewStep = async (dispatch, getState) => {
  const {
    navigation: { file: { fileType } },
    workflow: { steps, addNewStep, openAddNewDialog }
  } = getState()
  const updatedSteps = [...steps]
  updatedSteps.push({
    name: addNewStep.name,
    state: buildStepState(addNewStep.stepType),
    expand: true,
    checked: true,
    type: addNewStep.stepType
  })
  return dispatch({
    ...savedNewStepAction,
    payload: {
      steps: [...updatedSteps],
      fileType,
      openAddNewDialog: !openAddNewDialog
    }
  })
}

export const saveWorkflow = async (dispatch, getState) => {
  const {
    dataPrep: { visibleView: queryType = 'workflow' },
    navigation: { file: { fileType } },
    user: { ownerId: userId },
    workflow: { createdDate = moment().format(), desc, guid = null, name, steps }
  } = getState()
  dispatch({
    ...savingWorkflowAction,
    payload: { isSaving: true }
  })
  try {
    const workflows = await sourcesApi.saveWorkflow(userId, {
      desc,
      name,
      queryType,
      steps,
      updatedDate: moment().toString(),
      fileType,
      guid,
      createdDate
    })

    if (guid) {
      const updatedWorkflow = workflows.find((workflow) => workflow.guid === guid)
      return dispatch({
        ...savedWorkflowAction,
        payload: {
          dataPrep: {
            workflows
          },
          workflow: {
            fileType,
            updatedDate: updatedWorkflow.updatedDate,
            isSaving: false
          }
        }
      })
    } else {
      return dispatch({
        ...savedWorkflowAction,
        payload: {
          dataPrep: {
            workflows
          },
          workflow: {
            fileType,
            guid: workflows[workflows.length - 1].guid,
            createdDate: workflows[workflows.length - 1].createdDate,
            updatedDate: workflows[workflows.length - 1].updatedDate,
            isSaving: false
          }
        }
      })
    }
  } catch (ex) {
    return dispatch({
      ...savedWorkflowAction,
      payload: {
        workflow: {
          isSaving: false
        }
      }
    })
  }
}

export const executeWorkflow = async (dispatch, getState) => {
  const {
    dataPrep: { visibleView: queryType = 'workflow' },
    navigation: { file: { columns, fileType, guid: tableName } },
    project: { id },
    user: { ownerId: userId },
    workflow: { createdDate = moment().format(), desc, guid = null, name, steps }
  } = getState()

  const stepsClone = []
  for (const step of steps) {
    const updatedStep = {
      ...step,
      stepStatus: ''
    }
    stepsClone.push(updatedStep)
  }

  dispatch({
    ...executingWorkflowAction,
    payload: {
      isExecuting: true,
      isSaving: true,
      steps: stepsClone
    }
  })

  const toSave = {
    desc,
    name,
    queryType,
    steps,
    updatedDate: moment().toString(),
    fileType,
    guid: guid || null,
    createdDate: createdDate || moment().format()
  }

  try {
    const savedWorkflows = await sourcesApi.saveWorkflow(userId, toSave)
    const workflowState = guid ? savedWorkflows.find((workflow) => workflow.guid === guid) : savedWorkflows && savedWorkflows.length && savedWorkflows[savedWorkflows.length - 1]
    if (guid) {
      dispatch({
        ...executingSavedWorkflowAction,
        payload: {
          dataPrep: {
            savedWorkflows
          },
          workflow: {
            fileType,
            updatedDate: workflowState.updatedDate,
            isSaving: false
          }
        }
      })
    } else {
      dispatch({
        ...executingSavedWorkflowAction,
        payload: {
          dataPrep: {
            savedWorkflows
          },
          workflow: {
            fileType,
            guid: workflowState.guid,
            createdDate: workflowState.createdDate,
            updatedDate: workflowState.updatedDate,
            isSaving: false
          }
        }
      })
    }

    const { steps } = workflowState
    const formatedSteps = []

    for (let i = 0; i < steps.length; i++) {
      if (steps[i].checked) {
        switch (steps[i].type) {
          case 'convert': {
            const { fieldsToConvert } = steps[i].state
            formatedSteps.push({
              column: fieldsToConvert?.field?.value,
              dataType: fieldsToConvert?.dataType?.value,
              format: fieldsToConvert?.dataType?.value === 'date' ? fieldsToConvert.format.value : null,
              type: 'convert',
              index: i
            })
            break
          }
          case 'merge': {
            const dataToMerge = steps[i].state
            formatedSteps.push({
              ...dataToMerge,
              type: 'merge',
              index: i
            })
            break
          }
          case 'newColumn': {
            const { name, formula } = steps[i].state
            formatedSteps.push({
              newName: name.value,
              formula: formula.value,
              type: 'newColumn',
              index: i
            })
            break
          }
          case 'removeRows': {
            const deleteWhereConditions = steps[i].state.deleteWhereConditions
            formatedSteps.push({
              isDelete: true,
              deleteWhereConditions,
              type: 'update',
              index: i
            })
            break
          }
          case 'modify': {
            const updateSetConditions = steps[i].state.updateSetConditions
            const updateWhereConditions = steps[i].state.updateWhereConditions
            formatedSteps.push({
              updateSetConditions,
              updateWhereConditions,
              type: 'update',
              index: i
            })
            break
          }
          case 'aggregate': {
            const { selectedAggregators, fieldsAgg } = steps[i].state
            const aggregators = fieldsAgg.filter((a) => selectedAggregators.some(word => a.value === word))
            const columnsForPreview = columns.reduce((s, a) => {
              const current = aggregators.find(x => x.value === a.Name)
              if (current) {
                s.push({ value: current.aggregate, label: current.aggregate })
              } else {
                s.push({ value: a.Name, label: a.Name })
              }
              return s
            }, [])
            formatedSteps.push({
              querySelectFields: columnsForPreview,
              type: 'aggregate',
              index: i
            })
            break
          }
          default:
            break
        }
      }
    }
    const body = {
      projectId: id,
      info: {
        id,
        guid: tableName,
        workflowId: workflowState.guid,
        userId
      },
      steps: formatedSteps
    }

    await sourcesApi.executeWorkflow(body)

    while (true) {
      const workflows = await sourcesApi.getSavedWorkflows(userId)
      const executedWorkflow = workflows.find((workflow) => workflow.guid === workflowState.guid)
      const isFinished = ['FAILED', 'SUCCEEDED'].includes(executedWorkflow.workflowStatus)
      const action = isFinished ? executedWorfklowAction : executingSavedWorkflowAction
      dispatch({
        ...action,
        payload: {
          dataPrep: {
            workflows
          },
          workflow: {
            status: executedWorkflow.workflowStatus,
            steps: executedWorkflow.steps,
            isExecuting: !isFinished,
            isSaving: false
          }
        }
      })
      if (isFinished) {
        dispatch(loadData(id, true))
        break
      }
      await delay(5000)
    }
  } catch (ex) {
    return dispatch({
      ...executedWorfklowAction,
      payload: {
        workflow: {
          isExecuting: false,
          isSaving: false,
          executeMessage: ex ? ex.data.error : 'error'
        }
      }
    })
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case clearedWorkflowAction.type:
      return { ...initialState }
    case changedNewColumnAction.type:
    case updatedMergeReferenceAction.type:
    case savedWorkflowAction.type:
    case executingSavedWorkflowAction.type:
    case executedWorfklowAction.type:
    case openWorkflowAction.type:
      return {
        ...state,
        ...payload.workflow
      }
    case savedNewStepAction.type:
    case removedOrConditionsAction.type:
    case updatedSetListAction.type:
    case updatedWhereListAction.type:
    case executingWorkflowAction.type:
      return {
        ...state,
        ...payload
      }
    case selectedFieldsAction.type: {
      const { key, value = '', order } = payload
      const { steps } = state
      const fieldsToConvert = {
        ...steps[order].state.fieldsToConvert,
        [key]: value
      }
      const updatedSteps = getUpdatedSteps(steps, order, 'fieldsToConvert', fieldsToConvert)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case selectAggregatorsAction.type: {
      const { selected, order } = payload
      const { steps } = state
      const updatedSteps = getUpdatedSteps(steps, order, 'selectedAggregators', selected)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case removedSelectedAggregatorAction.type: {
      const { remove, order } = payload
      const { steps } = state
      const selectedAggregators = [
        ...steps[order].state.selectedAggregators.filter(a => a !== remove)
      ]
      const updatedSteps = getUpdatedSteps(steps, order, 'selectedAggregators', selectedAggregators)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case selectAggregationAction.type: {
      const { field, agg, order } = payload
      const { steps } = state
      const fieldsAgg = [...steps[order].state.fieldsAgg]
      const selectedAggregators = [...steps[order].state.selectedAggregators]
      const aggregators = fieldsAgg.filter(a => selectedAggregators.some(word => a.value === word))
      const exist = aggregators.find(a => a.value === field)
      if (exist) {
        exist.agg = agg
        exist.aggregate = getAggValue(agg, field)
      } else {
        aggregators.push({ value: field, agg, aggregate: getAggValue(agg, field) })
      }
      const updatedSteps = getUpdatedSteps(steps, order, 'fieldsAgg', aggregators)

      return {
        ...state,
        steps: updatedSteps
      }
    }
    case addedNewUpdateSetConditionAction.type: {
      const { order } = payload
      const { steps } = state
      const updatedCondition = {
        columnName: '',
        operation: '=',
        whereValue: ''
      }
      const updatedCondtions = [
        ...steps[order].state.updateSetConditions,
        updatedCondition
      ]
      const updatedSteps = getUpdatedSteps(steps, order, 'updateSetConditions', updatedCondtions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case addedNewWhereConditionAction.type: {
      const { order, isDelete } = payload
      const { steps } = state
      const conditions = isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'
      const updatedCondition = {
        columnName: '',
        operation: '',
        whereValue: ''
      }
      const updatedCondtions = [
        ...steps[order].state[conditions],
        updatedCondition
      ]
      const updatedSteps = getUpdatedSteps(steps, order, [conditions], updatedCondtions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case removedWhereConditionAction.type: {
      const { index, order, isDelete } = payload
      const { steps } = state
      const whereConditions = isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'
      const conditions = [
        ...steps[order].state[whereConditions].slice(0, index),
        ...steps[order].state[whereConditions].slice(index + 1)
      ]
      const updatedSteps = getUpdatedSteps(steps, order, [whereConditions], conditions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case removedSetConditionAction.type: {
      const { index, order } = payload
      const { steps } = state
      const conditions = [
        ...steps[order].state.updateSetConditions.slice(0, index),
        ...steps[order].state.updateSetConditions.slice(index + 1)
      ]
      const updatedSteps = getUpdatedSteps(steps, order, 'updateSetConditions', conditions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case addedOrFilterAction.type: {
      const { index, order, isDel } = payload
      const { steps } = state
      const orSwitch = [
        ...steps[order].state.orSwitchChecked
      ]
      let updatedSteps = []
      if (isDel) {
        orSwitch.splice(index, 1)
        updatedSteps = getUpdatedSteps(steps, order, 'orSwitchChecked', orSwitch)
      } else {
        const checked = !orSwitch[index]
        orSwitch[index] = checked
        updatedSteps = getUpdatedSteps(steps, order, 'orSwitchChecked', orSwitch)
      }
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case changedJoinItemAction.type: {
      const { name, value, index, order } = payload
      const { steps } = state
      const updatedConditions = getUpdatedConditions(steps, order, 'joinList', index, name, value)
      const updatedSteps = getUpdatedSteps(steps, order, 'joinList', updatedConditions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case removedJoinItemAction.type: {
      const { index, order } = payload
      const { steps } = state
      const joinList = [
        ...steps[order].state.joinList
      ]
      joinList.splice(index, 1)
      const updatedSteps = getUpdatedSteps(steps, order, 'joinList', joinList)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case addedJoinItemAction.type: {
      const { order } = payload
      const { steps } = state
      const item = {
        sourceColumn: {},
        sourceOperation: {},
        referenceColumn: {}
      }
      const joinList = [
        ...steps[order].state.joinList,
        item
      ]
      const updatedSteps = getUpdatedSteps(steps, order, 'joinList', joinList)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case addedWhereItemAction.type: {
      const { order } = payload
      const { steps } = state
      const item = {
        sourceColumn: {},
        sourceOperation: {},
        referenceColumn: {}
      }
      const whereList = [
        ...steps[order].state.whereList,
        item
      ]
      const updatedSteps = getUpdatedSteps(steps, order, 'whereList', whereList)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case updatedWhereItemAction.type: {
      const { name, value, index, order } = payload
      const { steps } = state
      const updatedConditions = getUpdatedConditions(steps, order, 'whereList', index, name, value)
      const updatedSteps = getUpdatedSteps(steps, order, 'whereList', updatedConditions)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case removedWhereItemAction.type: {
      const { index, order } = payload
      const { steps } = state
      const whereList = [
        ...steps[order].state.whereList
      ]
      whereList.splice(index, 1)
      const updatedSteps = getUpdatedSteps(steps, order, 'whereList', whereList)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case expandAction.type: {
      const { order } = payload
      const { steps } = state
      const expand = steps[order].expand
      const step = {
        ...steps[order],
        expand: !expand
      }
      const updatedSteps = [
        ...steps.slice(0, order),
        step,
        ...steps.slice(order + 1)
      ]
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case toggledWorkflowFormulaAction.type: {
      const { order } = payload
      const { steps } = state
      const step = {
        ...steps[order]
      }
      const open = !step.state.isFormulaDialogOpen
      const updatedSteps = getUpdatedSteps(steps, order, 'isFormulaDialogOpen', open)
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case setWorkFlowNewStepFieldAction.type: {
      const { key, value } = payload
      return {
        ...state,
        addNewStep: {
          ...state.addNewStep,
          [key]: value
        }
      }
    }
    case setWorkFlowFieldAction.type: {
      const { key, value } = payload
      return {
        ...state,
        [key]: value
      }
    }
    case toggledAddNewStepAction.type: {
      const { openAddNewDialog } = state
      return {
        ...state,
        openAddNewDialog: !openAddNewDialog
      }
    }
    case deletedStepAction.type: {
      const { viewIndex } = payload
      const { steps } = state
      const stepsCopy = [...steps]
      stepsCopy.splice(viewIndex, 1)
      return {
        ...state,
        steps: stepsCopy
      }
    }
    case checkedStepAction.type: {
      const { index } = payload
      const { steps } = state
      const checked = steps[index].checked
      const step = {
        ...steps[index],
        checked: !checked
      }
      const updatedSteps = [
        ...steps.slice(0, index),
        step,
        ...steps.slice(index + 1)
      ]
      return {
        ...state,
        steps: updatedSteps
      }
    }
    case movedUpAction.type: {
      const { viewIndex } = payload
      const { steps } = state
      const stepsCopy = [...steps]
      if (viewIndex <= 0) {
        return state
      } else {
        const step = stepsCopy[viewIndex - 1]
        stepsCopy[viewIndex - 1] = stepsCopy[viewIndex]
        stepsCopy[viewIndex] = step
        return {
          ...state,
          steps: stepsCopy
        }
      }
    }
    case movedDownAction.type: {
      const { viewIndex } = payload
      const { steps } = state
      const stepsCopy = [...steps]
      if (viewIndex < stepsCopy.length - 1) {
        const step = stepsCopy[viewIndex + 1]
        stepsCopy[viewIndex + 1] = stepsCopy[viewIndex]
        stepsCopy[viewIndex] = step
        return {
          ...state,
          steps: stepsCopy
        }
      } else {
        return state
      }
    }
    default:
      return state
  }
}
