import React from 'react'
import Grid from '@mui/material/Grid'
import ReactCreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { SingleValue, SingleValueStyles, AppendColumnBuilder } from '../../../../components/sql/SelectSingleValue'
import { updatedWhereList, removedWhereConditionAction, addedOrFilterAction, removeOrConditions } from '../../../../store/pages/common/workflow'
import { OPERATIONS_OPTIONS } from '../../../../options'

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const DeleteButton = styled(IconButton)(() => ({
  marginRight: 75,
  marginTop: 15
}))

const OR = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '19px',
  fontWeight: '600',
  marginBottom: '12px'
}))

const FullWidth = styled(Grid)(() => ({
  width: '100%'
}))

const WhereRow = styled(Grid)(() => ({
  display: 'flex',
  width: '100%'
}))

function WhereList (props) {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)

  const whereConditions = props.state.state[`${props.isDelete ? 'deleteWhereConditions' : 'updateWhereConditions'}`]
  const columnsPlusBuilder = AppendColumnBuilder(navigation.file.columns || [])

  const handleORSwitchChange = (index, event) => {
    const isChecked = event?.target?.checked
    dispatch({ ...addedOrFilterAction, payload: { index, order: props.order } })

    if (isChecked) {
      dispatch(updatedWhereList('orColumnName', index, '', props.order, props.isDelete))
      dispatch(updatedWhereList('orOperation', index, '', props.order, props.isDelete))
      dispatch(updatedWhereList('orWhereValue', index, '', props.order, props.isDelete))
    } else {
      dispatch(removeOrConditions('orColumnName', index, props.order, props.isDelete))
      dispatch(removeOrConditions('orOperation', index, props.order, props.isDelete))
      dispatch(removeOrConditions('orWhereValue', index, props.order, props.isDelete))
    }
  }

  const handleOptionWhereDelete = (index) => {
    dispatch({ ...addedOrFilterAction, payload: { index, order: props.order, isDel: true } })
    dispatch({ ...removedWhereConditionAction, payload: { index, order: props.order, isDelete: props.isDelete } })
  }

  return (
    <div>
      {
        whereConditions.map((item, index) => (
          <Grid container key={index}>
            <Grid item xs={3}>
              <Select
                data-testid='updateWhereList'
                placeholder=""
                name={`column-${index}!`}
                styles={SingleValueStyles}
                value={item.columnName}
                queryIndex={index}
                queryTarget={'queryWhereConditions'}
                queryKey={'columnName'}
                order={props.order}
                components={{ SingleValue }}
                options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updatedWhereList('columnName', index, newValue, props.order, props.isDelete))}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                placeholder=""
                name={`operation-${index}!`}
                styles={SingleValueStyles}
                value={item.operation}
                components={{ SingleValue }}
                options={OPERATIONS_OPTIONS}
                onChange={(newValue) => dispatch(updatedWhereList('operation', index, newValue, props.order, props.isDelete))}
              />
            </Grid>
            <Grid item xs={3}>
              <CreatableSelect
                data-testid='creatable-select'
                placeholder=""
                name={`where-${index}!`}
                styles={SingleValueStyles}
                value={item.whereValue}
                order={props.order}
                queryIndex={index}
                queryTarget={'queryWhereConditions'}
                queryKey={'whereValue'}
                components={{ SingleValue }}
                options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                onChange={(newValue) => dispatch(updatedWhereList('whereValue', index, newValue, props.order, props.isDelete))}

              />
            </Grid>
            <Grid item xs={1}>
              <DeleteButton
                data-testid='button-delete-1'
                aria-label="delete"
                onClick={handleOptionWhereDelete.bind(this, index, props.order)}
                size="large">
                <DeleteIcon fontSize="small" />
              </DeleteButton>
            </Grid>
            <Grid item xs={2}>
              <FormGroup>
                <FormControlLabel control={
                  <Switch
                    checked={props.state.state.orSwitchChecked[index]}
                    data-testid='switch-whereORToggle'
                    onChange={handleORSwitchChange.bind(this, index)}
                    color="primary"
                  />} label="Add OR Filter" />
              </FormGroup>
            </Grid>
            <Grid item xs={12}></Grid>
            {(props.state.state.orSwitchChecked[index] || item.orColumnName || item.orWhereValue) && <FullWidth>
              <OR item xs={8}>OR</OR>
              <WhereRow>
                <Grid item xs={3}>
                  <Select
                    placeholder=""
                    name={`column-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orColumnName}
                    queryIndex={index}
                    queryTarget={'queryWhereConditions'}
                    queryKey={'orColumnName'}
                    order={props.order}
                    components={{ SingleValue }}
                    options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                    onChange={(newValue) => dispatch(updatedWhereList('orColumnName', index, newValue, props.order, props.isDelete))}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Select
                    placeholder=""
                    name={`operation-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orOperation}
                    components={{ SingleValue }}
                    options={OPERATIONS_OPTIONS}
                    onChange={(newValue) => dispatch(updatedWhereList('orOperation', index, newValue, props.order, props.isDelete))}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CreatableSelect
                    placeholder=""
                    name={`where-${index}!`}
                    styles={SingleValueStyles}
                    value={item.orWhereValue}
                    queryIndex={index}
                    queryTarget={'queryWhereConditions'}
                    queryKey={'orWhereValue'}
                    order={props.order}
                    components={{ SingleValue }}
                    options={columnsPlusBuilder.map(x => ({ value: x.Name, label: x.Name }))}
                    onChange={(newValue) => dispatch(updatedWhereList('orWhereValue', index, newValue, props.order, props.isDelete))}
                  />
                </Grid>
              </WhereRow>
            </FullWidth>}
          </Grid>
        ))
      }
    </div>
  )
}

WhereList.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number,
  isDelete: PropTypes.bool
}

export default React.memo(WhereList)
