import React from 'react'
import Grid from '@mui/material/Grid'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import SearchIcon from '@mui/icons-material/Search'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import TransformIcon from '@mui/icons-material/Transform'
import MergeTypeIcon from '@mui/icons-material/MergeType'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import WorkIcon from '@mui/icons-material/Work'
import SyncIcon from '@mui/icons-material/Sync'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import FunctionsIcon from '@mui/icons-material/Functions'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { setSubNavigationAction } from '../../../store/pages/dataPrep'
import { getAccess } from '../../../store/pages/project/async'

const TABS = [
  {
    key: 'query',
    label: 'Query',
    icon: SearchIcon
  },
  {
    key: 'workflow',
    label: 'Workflow',
    icon: WorkIcon
  },
  {
    key: 'update',
    label: 'Update',
    icon: TransformIcon
  },
  {
    key: 'merge',
    label: 'Merge',
    icon: MergeTypeIcon
  },
  {
    key: 'convert',
    label: 'Convert',
    icon: SyncIcon
  },
  {
    key: 'newColumn',
    label: 'New Column',
    icon: PlaylistAddIcon
  },
  {
    key: 'removeRows',
    label: 'Remove Rows',
    icon: DeleteSweepIcon
  },
  {
    key: 'aggregate',
    label: 'Aggregate',
    icon: FunctionsIcon
  },
  {
    key: 'log',
    label: 'Log',
    icon: TrackChangesIcon
  }
]

const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginTop: theme.spacing(3)
}))

const Tab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== 'active'
})(props => ({
  display: 'flex',
  color: props.active ? 'white' : '#c62728',
  backgroundColor: props.active ? '#c62728' : 'white',
  padding: '10px 55px 10px 55px',
  '&:hover': {
    backgroundColor: '#bdbaba',
    color: props.active ? '#c62728' : 'white'
  }
}))

export default function Navigation () {
  const dispatch = useDispatch()

  const {
    userId,
    ownerId,
    role,
    studyType,
    currentView,
    sharedWithIds
  } = useSelector(({
    user: {
      ownerId: userId,
      role
    },
    project: {
      ownerId,
      studyType,
      sharedWithIds
    },
    dataPrep: {
      visibleView: currentView
    }
  }) => ({
    userId,
    ownerId,
    role,
    studyType,
    currentView,
    sharedWithIds
  }))

  const access = getAccess(
    sharedWithIds,
    ownerId,
    studyType,
    role,
    userId,
    'DATA_PREP'
  )

  const tabs = access ? TABS : [TABS[0], TABS[8]]
  const targetIndex = TABS.findIndex(tab => tab.key === currentView)
  const tabIndex = targetIndex !== -1 ? targetIndex : 0

  const isActive = tab => {
    return currentView === tab || (currentView === undefined && tab === 'query')
  }

  const handleTabChange = (_event, index) => {
    dispatch({
      ...setSubNavigationAction,
      payload: TABS[index].key
    })
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          data-testid='tabs'
          textColor="primary"
          value={tabIndex}
          onChange={handleTabChange}
        >
          {tabs.map(tab => (
            <Tab
              data-testid={`tab-${tab.key}`}
              key={tab.key}
              active={isActive(tab.key)}
              label={<><tab.icon /> {tab.label}</>}
            />
          ))}
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <hr/>
      </Grid>
    </Grid>
  )
}
