import React from 'react'
import MuiGrid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

import { SingleValue, SingleValueStyles } from '../../../../../components/sql/SelectSingleValue'
import { addedWhereItemAction, updatedWhereItemAction, removedWhereItemAction } from '../../../../../store/pages/common/workflow'
import { OPERATIONS_OPTIONS } from '../../../../../options'

const Grid = styled(MuiGrid)(({ theme }) => ({
  margin: theme.spacing(1)
}))

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1),
  textAlign: 'left'
}))

const WhereList = ({ state, order }) => {
  const dispatch = useDispatch()

  const navigation = useSelector((state) => state.navigation)
  const dataPrep = useSelector((state) => state.dataPrep)

  const sourceFileColumns = navigation.file.columns || []
  const whereList = state.state.whereList

  return (
    <Grid container>
      <Grid item xs={7}>
        <Typography variant="h6">
            Filters
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => dispatch({ ...addedWhereItemAction, payload: { order } })}

        >
          <AddIcon/>
        </Fab>
      </Grid>
      {
        whereList.map((whereItem, index) =>
          <Grid key={index} container>
            <Grid item xs={3}>
              <Select
                placeholder=""
                styles={SingleValueStyles}
                value={Object.keys(whereItem.sourceColumn).length === 0 ? undefined : whereItem.sourceColumn}
                options={sourceFileColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch({ ...updatedWhereItemAction, payload: { name: 'sourceColumn', value: newValue, index, order } })}
              />
            </Grid>
            <Grid item xs={1}>
              <Select
                placeholder=""
                value={Object.keys(whereItem.sourceOperation).length === 0 ? undefined : whereItem.sourceOperation}
                styles={SingleValueStyles}
                options={OPERATIONS_OPTIONS}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch({ ...updatedWhereItemAction, payload: { name: 'sourceOperation', value: newValue, index, order } })}

              />
            </Grid>
            <Grid item xs={3}>
              <CreatableSelect
                placeholder=""
                value={Object.keys(whereItem.referenceColumn).length === 0 ? undefined : whereItem.referenceColumn}
                styles={SingleValueStyles}
                options={dataPrep.referenceColumns.map(x => ({ value: x.Name, label: `${x.Name} (${x.Type})` }))}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch({ ...updatedWhereItemAction, payload: { name: 'referenceColumn', value: newValue, index, order } })}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                aria-label="delete"
                onClick={() => dispatch({ ...removedWhereItemAction, payload: { index, order } })}
                size="large">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
}

WhereList.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

export default React.memo(WhereList)
