import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'

import JoinList from './components/joinList'
import WhereList from './components/whereList'
import SynonymDialog from '../../../../components/sql/SynonymDialog'
import { MultiValueLabel, MultiValueRemove, MultiValueStyles } from '../../../../components/sql/SelectMultiValue'
import { SingleValue, SingleValueStyles, AppendAllStar } from '../../../../components/sql/SelectSingleValue'
import SaveQueryDialogLegacy from '../../components/SaveQueryDialogLegacy'
import { updateMergeReference, updateReferenceColumns } from '../../../../store/pages/common/workflow'
import { OPTIONS_JOIN } from '../../../../options'

const StyledGrid = styled(Grid)(() => ({
  textAlign: 'left',
  width: '100%',
  minHeight: 700
}))

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1),
  textAlign: 'left'
}))

const Form = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%'
}))

Reference.propTypes = {
  state: PropTypes.object,
  actions: PropTypes.object,
  order: PropTypes.number
}

function Reference (props) {
  const dispatch = useDispatch()

  const dataPrep = useSelector((state) => state.dataPrep)
  const navigation = useSelector((state) => state.navigation)
  const synonymDialog = useSelector((state) => state.synonymDialog)

  let columns = navigation.file.columns || []
  const mergeData = props.state.state
  columns = AppendAllStar(mergeData.fileColumns || [], columns)

  React.useEffect(() => {
    dispatch(updateReferenceColumns(props.order))
  }, [props.order, mergeData.referenceTable, dispatch])

  return (
    <Grid container>
      <Dialog
        data-testid='dialog-workflow-reference'
        open={synonymDialog.open}
        aria-labelledby='form-dialog-title'
        maxWidth='md'
        fullWidth={false}
      >
        <SynonymDialog
          isWorkflow={true}
          mergeData={mergeData}
        />
      </Dialog>
      <StyledGrid container>

        <Form elevation={0} outlined>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant='h6'>
                              Reference Table
              </Typography>
              <Select
                data-testid='select-referenceTable'
                placeholder=''
                value={Object.keys(mergeData.referenceTable).length === 0 ? '' : mergeData.referenceTable}
                styles={SingleValueStyles}
                options={dataPrep.referenceTables.data.map(table => ({ value: table.guid, label: `${table.tableName}` }))}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch(updateMergeReference('referenceTable', newValue, props.order))}
              />
              <Typography variant='h6'>
                              Reference Columns  { mergeData.isLoading ? <CircularProgress /> : '' }
              </Typography>
              <Select
                data-testid='select-referenceColumn'
                styles={MultiValueStyles}
                placeholder=''
                isMulti
                value={mergeData.referenceColumns}
                allowEdit={true}
                queryKey={'referenceColumns'}
                options={dataPrep.referenceColumns.map(column => ({ value: column.Name, label: `${column.Name} (${column.Type})` }))}
                components={{ MultiValueLabel, MultiValueRemove }}
                onChange={(newValue) => {
                  const result = newValue || []
                  dispatch(updateMergeReference('referenceColumns', result, props.order))
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6'>
                              File Columns
              </Typography>
              <Select
                data-testid='select-fileColumn'
                styles={MultiValueStyles}
                placeholder=''
                isMulti
                value={mergeData.fileColumns}
                options={columns.map(column => ({ value: column.Name, label: `${column.Name} (${column.Type})` }))}
                components={{ MultiValueLabel, MultiValueRemove }}
                onChange={(newValue) => {
                  const result = newValue || []
                  dispatch(updateMergeReference('fileColumns', result, props.order))
                }}

              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Typography variant='h6'>
                              Join Type
              </Typography>
              <Select
                data-testid='select-joinType'
                placeholder=''
                value={mergeData.joinType}
                styles={SingleValueStyles}
                options={OPTIONS_JOIN}
                components={{ SingleValue }}
                onChange={(newValue) => dispatch(updateMergeReference('joinType', newValue, props.order))}

              />
            </Grid>
            <Grid item xs={12}>
              <JoinList state = {props.state} order= {props.order}/>
            </Grid>
            <Grid item xs={12}>
              <WhereList state = {props.state} order= {props.order}/>
            </Grid>
          </Grid>
        </Form>
      </StyledGrid>
      <Dialog
        data-testid='dialog-saveQuery'
        open={dataPrep.saveQueryDialog.open}
        aria-labelledby="form-dialog-title"
        maxWidth='md'
        fullWidth={false}
      >
        <SaveQueryDialogLegacy />
      </Dialog>
    </Grid>
  )
}

export default React.memo(Reference)
