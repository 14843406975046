import React from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ReactCreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import {
  SingleValue,
  SingleValueStyles
} from '../../../components/sql/SelectSingleValue'

import {
  removedWhereConditionAction,
  updateOptionQueryWhereList
} from '../../../store/pages/common/query'
import { OPERATIONS_OPTIONS } from '../../../options'

const COMPONENTS = { SingleValue }

const Select = styled(ReactSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const CreatableSelect = styled(ReactCreatableSelect)(({ theme }) => ({
  minHeight: 58,
  margin: theme.spacing(1)
}))

const DeleteButton = styled(IconButton)({
  marginRight: 75,
  marginTop: 15
})

export default function ConditionsList () {
  const dispatch = useDispatch()
  const reference = useSelector(store => store.reference)
  const query = useSelector(store => store.query)

  const columnOptions = reference.referenceSourceInfo.columns.map(item => ({
    label: item.Name,
    value: item.Name
  }))

  const handleSelect = (value, event) => {
    const [key, rawIndex] = event.name.split('.')
    const index = Number(rawIndex)

    dispatch(updateOptionQueryWhereList(key, index, value))
  }

  return (
    <div>
      {query.queryWhereConditions.map((item, index) => (
        <Grid container key={index}>
          <Grid item xs={3}>
            <CreatableSelect
              data-testid={`select-columnName.${index}`}
              name={`columnName.${index}`}
              styles={SingleValueStyles}
              isClearable
              components={COMPONENTS}
              queryKey='columnName'
              queryIndex={index}
              queryTarget='queryWhereConditions'
              openMenuOnClick={item.columnName === ''}
              options={columnOptions}
              value={item.columnName}
              onChange={handleSelect}
            />
          </Grid>

          <Grid item xs={2}>
            <Select
              data-testid={`select-operation.${index}`}
              name={`operation.${index}`}
              styles={SingleValueStyles}
              isClearable
              components={COMPONENTS}
              queryIndex={index}
              queryTarget='queryWhereConditions'
              options={OPERATIONS_OPTIONS}
              value={item.operation}
              onChange={handleSelect}
            />
          </Grid>

          <Grid item xs={3}>
            <CreatableSelect
              data-testid={`select-whereValue.${index}`}
              name={`whereValue.${index}`}
              styles={SingleValueStyles}
              isClearable
              components={COMPONENTS}
              queryKey='whereValue'
              queryIndex={index}
              queryTarget='queryWhereConditions'
              openMenuOnClick={item.whereValue === ''}
              options={columnOptions}
              value={item.whereValue}
              onChange={handleSelect}
            />
          </Grid>

          <Grid item xs={4}>
            <DeleteButton
              data-testid={`button-delete-${index}`}
              size="large"
              onClick={() => dispatch({
                ...removedWhereConditionAction,
                payload: index
              })}
            >
              <DeleteIcon />
            </DeleteButton>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
