import PropTypes from 'prop-types'
import React from 'react'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Fab from '@mui/material/Fab'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import StepConnector from '@mui/material/StepConnector'
import Stepper from '@mui/material/Stepper'
import MenuIcon from '@mui/icons-material/Menu'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ExploreIcon from '@mui/icons-material/Explore'
import InputIcon from '@mui/icons-material/Input'
import SyncIcon from '@mui/icons-material/Sync'
import HomeIcon from '@mui/icons-material/Home'
import MapIcon from '@mui/icons-material/Map'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import UpdateIcon from '@mui/icons-material/Update'
import AddIcon from '@mui/icons-material/Add'
import StorageIcon from '@mui/icons-material/Storage'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import CastConnectedIcon from '@mui/icons-material/CastConnected'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ListItemButton, styled } from '@mui/material'
import ReleaseNoteDialog from './ReleaseNoteDialog'
import AnnouncementDialog from './AnnouncementDialog'
import TopMenu from './TopMenu'
import MenuInfo from './MenuInfo'
import logo from '../logo.png'
import { setDrawerAction } from '../../store/pages/global'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, NavLink } from 'react-router-dom'
import { hidCurrentAnnouncementAction } from '../../store/pages/adminTool'

import { useTheme } from '@mui/material/styles'

const WIDTH_DRAWER = 240

const PATH_REFERENCE = 'reference'
const LABEL_PROJECT_NEW = 'New Project'
const LABEL_REFERENCE_NEW = 'New Reference Table'
const LABEL_HOME = 'Home'
const LABEL_UPLOAD = 'Upload'
const LABEL_PREP = 'Data Prep'
const LABEL_REVIEW = 'Review'
const LABEL_MAPPING = 'Column Mapping'
const LABEL_MAPPING_VALUE = 'Value Mapping'
const LABEL_CALC = 'Calculation'
const LABEL_CALC_PRE = 'Pre-Calculation'
const LABEL_CALC_POST = 'Post-Calculation'

const LINK_KNOWLEDGE_CENTER = {
  path: 'https://rga.service-now.com/rsp?kb_id=12c6dfbf1bc9ec10a283ececbc4bcb14&id=kb_view2',
  label: 'Knowledge Center',
  icon: <CastConnectedIcon />
}

const LINKS_APP = [
  {
    path: '/',
    label: 'Experience Studies',
    icon: <ListAltIcon />
  },
  {
    path: '/',
    label: 'Expected Basis',
    icon: <StorageIcon />
  },
  {
    path: '/reference',
    label: 'Reference Tables',
    icon: <PermMediaIcon />
  }
]

const COMMON_LINKS_PROJECT = [
  {
    path: '/upload',
    label: 'New Project',
    icon: <AddIcon />
  },
  {
    path: '/',
    label: 'Home',
    icon: <HomeIcon />
  },
  {
    path: '/upload',
    label: 'Upload',
    icon: <CloudUploadIcon />
  },
  {
    path: '/prep',
    label: 'Data Prep',
    icon: <ExploreIcon />
  },
  {
    path: '/columnmapping',
    label: 'Column Mapping',
    icon: <MapIcon />
  },
  {
    path: '/valuemapping',
    label: 'Value Mapping',
    icon: <FormatListBulletedIcon />
  },
  {
    path: '/precalculation',
    label: 'Pre-Calculation',
    icon: <PlaylistAddCheckIcon />
  },
  {
    path: '/calculation',
    label: 'Calculation',
    icon: <CloudQueueIcon />
  },
  {
    path: '/postcalculation',
    label: 'Post-Calculation',
    icon: <StorageIcon />
  }
]

const UTILITY_LINKS = [
  {
    path: '/review',
    label: 'Review',
    icon: <InputIcon />
  }
]

const REDI_LINKS = COMMON_LINKS_PROJECT.concat(UTILITY_LINKS)

const LINKS_REFERENCE = [
  {
    path: '/reference/new/upload',
    label: 'New Reference Table',
    icon: <AddIcon />
  },
  {
    path: '/reference',
    label: 'Home',
    icon: <HomeIcon />
  },
  {
    path: '/upload',
    label: 'Upload',
    icon: <CloudUploadIcon />
  },
  {
    path: '/query',
    label: 'Query',
    icon: <QueryBuilderIcon />
  },
  {
    path: '/update',
    label: 'Update',
    icon: <UpdateIcon />
  },
  {
    path: '/insert',
    label: 'Insert',
    icon: <AddCircleOutlineIcon />
  },
  {
    path: '/convert',
    label: 'Convert',
    icon: <SyncIcon />
  }
]

const isValidReference = (path, files) =>
  path.split('/')[2] !== 'new' && files.length > 0

const isValidPrep = (label, files) =>
  files.length > 0 && [
    LABEL_PREP,
    LABEL_MAPPING,
    LABEL_MAPPING_VALUE
  ].includes(label)

const isValidCalc = (label, files) =>
  files.length > 0 && [
    LABEL_CALC_PRE,
    LABEL_CALC
  ].includes(label)

const isValidPostCalc = (label, runs, studyType) =>
  runs && runs.length > 0 &&
      [
        LABEL_CALC_POST,
        LABEL_REVIEW
      ].includes(label)

const isValidNormal = (label, project, jobHistory) =>
  isValidPrep(label, project.files) ||
  isValidCalc(label, project.formatFiles) ||
  isValidPostCalc(label, jobHistory, project.studyType)

const getStepFontWeight = (pathname, step) => {
  const samePath = pathname === step.path
  const inPathname = pathname.includes(step.path)
  const inCurrentPage = samePath || (step.label !== LABEL_HOME && inPathname)

  return inCurrentPage ? 700 : 400
}

const initVarsREDI = path => (
  path === PATH_REFERENCE
    ? {
        steps: LINKS_REFERENCE,
        pathfunc: getStepReferencePath
      }
    : {
        steps: REDI_LINKS,
        pathfunc: getStepProjectPath
      }
)

const getStepProjectPath = (label, path, projectId) => {
  if (projectId && label !== 'Home' && label !== 'Reference Tables' && label !== 'New Project') {
    path += `/${projectId}`
  }
  if (label === 'Experience Studies') {
    path = '/'
  }

  return path
}

const getStepReferencePath = (label, path, tableId) => {
  if (tableId && label !== 'Home' && label !== 'Experience Studies' && label !== 'New Reference Table' && label !== 'Reference Tables') {
    return `/reference/${tableId}${path}`
  } else {
    const routePath = !tableId ? `/new${path}` : path
    return label === 'Upload' ? `reference${routePath}` : path
  }
}

const StyledImg = styled('img')({
  width: '150px',
  height: '50px'
})

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({ theme, isDrawerOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(isDrawerOpen && {
    width: `calc(100% - ${WIDTH_DRAWER}px)`,
    marginLeft: `${WIDTH_DRAWER}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const openedMixin = (theme) => ({
  width: WIDTH_DRAWER,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(14)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(15)} + 1px)`
  }
});

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'isDrawerOpen' })(
  ({ theme, isDrawerOpen }) => ({
    width: WIDTH_DRAWER,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isDrawerOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!isDrawerOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({ isDrawerOpen }) => ({
  marginRight: 5,
  ...(isDrawerOpen && {
    display: 'none'
  })
}))

const StyledMain = styled('main')(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(16),
  background: 'white'
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const RootDiv = styled('div')({
  display: 'flex'
})

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: theme.spacing(2)
}))

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({ theme, isDrawerOpen }) => ({
  minHeight: 48,
  justifyContent: 'center',
  paddingRight: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  ...(isDrawerOpen && {
    justifyContent: 'initial'
  })
}))

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({ theme, isDrawerOpen }) => ({
  minWidth: 0,
  marginRight: 'auto',
  justifyContent: 'center',
  ...(isDrawerOpen && {
    marginRight: theme.spacing(3)
  })
}))

const StyledListItem = styled(ListItem)({
  display: 'block'
})

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen'
})(({ theme, isDrawerOpen }) => ({
  opacity: 0,
  ...(isDrawerOpen && {
    opacity: 1,
    marginLeft: theme.spacing(5)
  })
}))

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4)
}))

const StyledStepConnector = styled(StepConnector)({
  padding: '8px 10px'
})

const StyledStep = styled(Step)({
  padding: '0px 10px'
})

const StepIcon = props => {
  return (
    <Tooltip title={props.label}>
      {props.icon}
    </Tooltip>
  )
}

StepIcon.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node
}

const Navigation = children => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openRelease, setOpenRelease] = React.useState(true)

  const {
    isLoaded,
    isSaving,
    drawerOpen,
    user,
    project,
    currentRelease,
    currentAnnouncement,
    jobHistory,
    referenceTable
  } = useSelector(({
    global: {
      isLoaded,
      isSaving,
      drawerOpen
    },
    user,
    adminTool: {
      currentRelease,
      currentAnnouncement
    },
    project,
    calculation: {
      jobHistory
    },
    reference: {
      referenceTable
    }
  }) => ({
    isLoaded,
    isSaving,
    drawerOpen,
    user,
    project,
    currentRelease,
    currentAnnouncement,
    jobHistory,
    referenceTable
  }))

  const admin = user.role === 'admin'
  const userOwnerEmail = user.ownerId.toLowerCase()
  const projectOwnerEmail = project.ownerId && project.ownerId.toLowerCase()
  const projectOwner = projectOwnerEmail === userOwnerEmail ? 'Owner' : null
  const sharedItems = project?.sharedWithIds || []
  const sharedItem = sharedItems.find(item => item.id.toLowerCase() === userOwnerEmail)
  const role = sharedItem ? sharedItem.role : (projectOwner || user.role)
  const announcement = currentAnnouncement ? currentAnnouncement[0] : null
  const release = currentRelease ? currentRelease[0] : null

  /* eslint-disable-next-line no-unused-vars */
  const [_unused, path, id] = location.pathname.split('/')
  const { steps, pathfunc } = initVarsREDI(path)

  const checkIfEnabled = ({ label }) => {
    if (label === 'Calculation' && project.studyType === 'redi') {
      const files = project.formatFiles.length === 6
      return files
    }

    if ([LABEL_HOME, LABEL_UPLOAD, LABEL_PROJECT_NEW, LABEL_REFERENCE_NEW].includes(label)) {
      return true
    }

    if (label === LABEL_CALC_PRE || label === LABEL_CALC) {
      return project.formatFiles.length > 0
    }

    if (label === LABEL_CALC_POST || label === LABEL_REVIEW) {
      return (project.formatFiles.length > 0 && jobHistory && jobHistory.length > 0)
    }

    if (path === PATH_REFERENCE) {
      return isValidReference(location.pathname, referenceTable.files)
    } else if (id !== undefined && !isSaving) {
      return isValidNormal(label, project, jobHistory)
    }

    return false
  }

  const handleDrawerOpen = () => {
    dispatch({ ...setDrawerAction, payload: true })
  }

  const handleDrawerClosed = () => {
    dispatch({ ...setDrawerAction, payload: false })
  }

  const handleAnnouncementClose = () => {
    dispatch({
      ...hidCurrentAnnouncementAction,
      payload: announcement.guid
    })
  }

  const handleReleaseClose = () => {
    setOpenRelease(false)
  }

  return (
    <RootDiv>
      <StyledAppBar position='fixed' isDrawerOpen={drawerOpen}>
        <Toolbar>
          <StyledIconButton
            data-testid='button-openDrawer'
            isDrawerOpen={drawerOpen}
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            size="large">
            <MenuIcon />
          </StyledIconButton>

          <TopMenu
            role={role}
            projectName={project.projectName}
          />
        </Toolbar>
      </StyledAppBar>

      <StyledDrawer
        variant="permanent"
        isDrawerOpen={drawerOpen}
      >
        <DrawerHeader>
          <NavLink
            style={{ textDecoration: 'none', color: 'black' }}
            to="/"
          >
            <StyledImg src={logo} alt="logo" />
          </NavLink>

          <IconButton
            data-testid='button-drawerClose'
            onClick={handleDrawerClosed}
            size="large">
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          {LINKS_APP.map((app, index) =>
            app.label === 'Expected Basis'
              ? (
              <NavLink key={'expectedBasis'} style={{ textDecoration: 'none', color: 'black' }} to="https://expected-basis.rgare.net/#/home">
                <StyledListItem key={app.label} disablePadding>
                  <StyledListItemButton
                  isDrawerOpen={drawerOpen}>
                  <StyledListItemIcon
                  isDrawerOpen={drawerOpen}>
                    <StepIcon {...app} />
                  </StyledListItemIcon>
                  <StyledListItemText primary={app.label} isDrawerOpen={drawerOpen}/>
                  </StyledListItemButton>
                </StyledListItem>
                </NavLink>)
              : (
                <NavLink
                  key={index}
                  to={pathfunc(app.label, app.path, id)}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                <StyledListItem key={app.label} disablePadding >
                  <StyledListItemButton
                   isDrawerOpen={drawerOpen}
                  >
                  <StyledListItemIcon
                  isDrawerOpen={drawerOpen}
                  >
                    <StepIcon {...app} />
                  </StyledListItemIcon>
                  <StyledListItemText primary={app.label} isDrawerOpen={drawerOpen}/>
                  </StyledListItemButton>
                </StyledListItem>
                </NavLink>
                )
          )}

          <a
            href={LINK_KNOWLEDGE_CENTER.path}
            target='_blank'
            rel="noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <StyledListItem key='Knowledge Center' disablePadding>
            <StyledListItemButton
                   isDrawerOpen={drawerOpen}
                  >
                  <StyledListItemIcon
                  isDrawerOpen={drawerOpen}
                  >
                    <StepIcon {...LINK_KNOWLEDGE_CENTER} />
                  </StyledListItemIcon>
                  <StyledListItemText primary={LINK_KNOWLEDGE_CENTER.label} isDrawerOpen={drawerOpen}/>
                  </StyledListItemButton>
            </StyledListItem>
          </a>
        </List>

        <Divider />

        <StyledStepper
          nonLinear
          orientation="vertical"
          connector={<StyledStepConnector />}
        >
          {steps.map((step) => (
            <StyledStep key={step.label}>
              <StepButton
                data-testid={`step-${step.label}`}
                completed
                disabled={!checkIfEnabled(step)}
                icon={<StepIcon {...step} />}
                onClick={() => {
                  navigate(pathfunc(step.label, step.path, id))
                }}
              >
                <Typography style={{
                  fontSize: 14,
                  color: 'black',
                  fontWeight: getStepFontWeight(location.pathname, step)
                }}>{step.label}</Typography>
              </StepButton>
            </StyledStep>
          ))}
        </StyledStepper>

        {admin && (
          <Tooltip title='Admin tool '>
            <StyledFab
              data-testid='fab-adminSettings'
              color='secondary'
              size="small"
              label="hey"
              onClick={() => navigate('/settings')}
            >
              <SettingsIcon/>
            </StyledFab>
          </Tooltip>
        )}

        <RootDiv>
          <MenuInfo />
        </RootDiv>
      </StyledDrawer>

      <StyledMain>
        {isLoaded ? children.props : ''}
      </StyledMain>

      {announcement && (
        <AnnouncementDialog
          data-testid='announcement'
          open={announcement.visible}
          title={announcement.title}
          reason={announcement.message}
          onClose={handleAnnouncementClose}
        />
      )}

      {release && (
        <ReleaseNoteDialog
          data-testid='release'
          open={openRelease}
          title={release.name}
          data={release}
          onClose={handleReleaseClose}
        />
      )}
    </RootDiv>
  )
}

Navigation.propTypes = {
  children: PropTypes.node
}

export default Navigation
